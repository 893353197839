import React, {Component} from 'react';
import GrupoMola from '../img/grupo-mola.png';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: [
                'Repasses'
            ]
        };
    }

    render() {

        return <section className="bg-pattern-light pt-5 pb-5">
            <div className="container-fluid">
                <div className="row justify-content-center align-items-center">
                    <div className="col-12 mb-3 col-md-auto mr-md-auto mb-md-0">
                        <p className="small text-center text-md-left m-0">
                            Copyright © 2019 Mola Plataforma de Gestão - Todos os direitos reservados<br/>
                            Desenvolvido pelo Grupo Mola Corban ® - 27.358.378/0001-18
                        </p>
                    </div>
                    <div className="col-auto">
                        <img src={GrupoMola} alt="Grupo Mola Corban" className="img-fluid" style={{maxHeight: '75px'}}/>
                    </div>
                </div>
            </div>
        </section>;
    }
}

export default Footer;
