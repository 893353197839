import React, {Component} from 'react';
import Dialog from 'react-bootstrap-dialog'
import {createDateFromFormat, formatarDataDatepicker, dateDiffDays} from '../helpers/Data'
import axios from "axios";
import { Button } from 'react-bootstrap'

class Filtro extends Component {
    constructor(props) {
        let hoje = new Date();
        super(props);
        this.state = {
            data_final: formatarDataDatepicker(hoje),
            data_inicial: formatarDataDatepicker(hoje.setDate(hoje.getDate() - 7)),

        };
    }

    handleChange = (event) => {
        const {id, value} = event.target;
        return this.setState({
            [id]: value
        });
    };

    consultar = () => {
        let data_inicial = createDateFromFormat(this.state.data_inicial, 'yyyy-mm-dd');
        let data_final = createDateFromFormat(this.state.data_final, 'yyyy-mm-dd');
        if (data_inicial > data_final) {
            return this.dialog.show({
                body:'A data final deve ser posterior à data inicial.',
                actions: [Dialog.OKAction()],
                bsSize: 'sm modal-dialog-centered'
            });
        }
        if (dateDiffDays(data_inicial, data_final) > 60) {
            return this.dialog.show({
                body:'O intervalo máximo entre as datas deve ser de 60 dias.',
                actions: [Dialog.OKAction()],
                bsSize: 'sm modal-dialog-centered'
            });
        }
        this.carregarRepassesProcessados(false);
    };

    componentDidMount() {
        this.carregarRepassesProcessados(true);
    }

    // passando parametro de ignorar erro para evitar alerta duplicado nos casos de erro de conexao com a API
    carregarRepassesProcessados = (ignorarErro) => {
        let dados = {
            "data_inicial": this.state.data_inicial,
            "data_final": this.state.data_final,
        };
        if(this.props.hash) {
            this.props.setLoading(true);
            axios.post(this.props.urlRepassesAPI + 'processados/' + this.props.hash, {dados})
            .then(res => {
                if(!res.data.erro) {
                    this.props.setLoading(false);
                    if (res.data[0]) {
                        return this.props.setRepassesProcessados(res.data[0].RepassesProcessados);
                    }
                    return this.props.setRepassesProcessados(null);
                } else {
                    this.props.setLoading(false);
                    if(!ignorarErro) {
                        return this.dialog.show({
                            body: res.data.erro,
                            actions: [Dialog.OKAction()],
                            bsSize: 'sm modal-dialog-centered'
                        });
                    }
                }
            })
            .catch(error => {
                this.props.setLoading(false);
                if (!ignorarErro) {
                    return this.dialog.show({
                        body:'Ocorreu um erro de conexão com o banco. Por favor, tente novamente em instantes.',
                        actions: [Dialog.OKAction()],
                        bsSize: 'sm modal-dialog-centered'
                    });
                }
            });
        }
    };

    render() {
        return <form className="row mb-5">
            <Dialog ref={(component) => {
                this.dialog = component
            }}/>
            <div className="col-12 col-sm-6 col-md-3">
                <label htmlFor="data_inicial">Data inicial</label>
                <div className="input-group flex-nowrap">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="calendar-alt" className="svg-inline--fa fa-calendar-alt fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path fill="currentColor" d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"/>
                            </svg>
                        </span>
                    </div>
                    <input type="date" id="data_inicial" className="form-control" value={this.state.data_inicial} onChange={this.handleChange} data-toggle="tooltip" title="Data inicial"/>
                </div>
            </div>
            <div className="col-12 mt-3 col-sm-6 mt-sm-0 col-md-3 mt-md-0">
                <label htmlFor="data_final">Data final</label>
                <div className="input-group flex-nowrap">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="calendar-alt" className="svg-inline--fa fa-calendar-alt fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path fill="currentColor" d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"/>
                            </svg>
                        </span>
                    </div>
                    <input type="date" id="data_final" className="form-control" value={this.state.data_final} onChange={this.handleChange} data-toggle="tooltip" title="Data final"/>
                </div>
            </div>
            <div className="col-12 mt-3 col-sm-6 col-md-auto mt-md-0 d-flex align-items-end">
                <Button variant={'success'} onClick={this.consultar} block>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="filter" className="svg-inline--fa fa-filter fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor" d="M487.976 0H24.028C2.71 0-8.047 25.866 7.058 40.971L192 225.941V432c0 7.831 3.821 15.17 10.237 19.662l80 55.98C298.02 518.69 320 507.493 320 487.98V225.941l184.947-184.97C520.021 25.896 509.338 0 487.976 0z"/>
                    </svg> Consultar
                </Button>
            </div>
            <div className="col-12 mt-3 col-sm-6 col-md-auto mt-md-0 ml-auto d-flex align-items-end">
                <Button variant={'mola'} onClick={() => this.props.updateModalTabelaStatus(true)} block>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="table" className="svg-inline--fa fa-table fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor" d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64v-96h160v96zm0-160H64v-96h160v96zm224 160H288v-96h160v96zm0-160H288v-96h160v96z"/>
                    </svg> Minha tabela
                </Button>
            </div>
        </form>;
    }
}

export default Filtro;
