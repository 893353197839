export function formatarDataDatepicker(dataMiliseconds) {
    let data = new Date(dataMiliseconds);
    let dia = data.getDate().toString().padStart(2, '0');
    let mes = (data.getMonth() + 1).toString().padStart(2, '0'); //+1 pois no getMonth Janeiro começa com zero.
    let ano = data.getFullYear();
    return ano + "-" + mes + "-" + dia;
}

export function formatarData2BR(data) {
    if (data === null) {
        return data
    }
    data = new Date(data);
    let dia = data.getDate().toString().padStart(2, '0');
    let mes = (data.getMonth() + 1).toString().padStart(2, '0'); //+1 pois no getMonth Janeiro começa com zero.
    let ano = data.getFullYear();
    return dia + '/'  + mes + '/' + ano;
}

export function createDateFromFormat(input, format) {
    format = format || 'yyyy-mm-dd'; // default format
    let parts = input.match(/(\d+)/g),
    i = 0, fmt = {};
    // extract date-part indexes from the format
    format.replace(/(yyyy|dd|mm)/g, function (part) {
        fmt[part] = i++;
    });

    return new Date(parts[fmt['yyyy']], parts[fmt['mm']] - 1, parts[fmt['dd']]);
}

export function dateDiffDays(first, second) {
    return Math.round((second - first) / 86400000);
}
