import React, {Component} from 'react';
import {Modal, Button, Alert} from 'react-bootstrap'
import {formatarData2BR} from '../helpers/Data'
import {formatMoney, formatPercentual} from "../helpers/Valor";
import axios from "axios";
import Dialog from "react-bootstrap-dialog";
import MaterialTable from 'material-table';

class ModalTabelaRepasses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            regras: {},
            vigencia_selecionada: 0,
        };
    }

    changeVigencia = (event) => {
        const {value} = event.target;
        this.props.updateVigenciaSelecionada(value);
        this.buscarRegras(value)
    };

    componentDidUpdate(prevProps) {
        // Uso típico, (não esqueça de comparar as props):
        if (this.props.repasseTabela.nome !== prevProps.repasseTabela.nome) {
            let vigencias = this.props.repasseTabela.RepassesVigencia;
            let vigencia_id = vigencias[0].id;

            this.props.updateVigenciaSelecionada(vigencia_id);
            this.buscarRegras(vigencia_id)
        }
    }

    buscarRegras = (vigencia_selecionada) => {
        //busca regras para a vigencia selecionada
        this.props.setLoading(true);
        axios.get(this.props.urlRepassesAPI + 'get_regras/' + this.props.hash + '/' + vigencia_selecionada)
        .then(res => {
            if(!res.data.erro) {
                this.setState({
                    regras: res.data,
                });
                this.props.setLoading(false);
            } else {
                this.props.setLoading(false);
                return this.dialog.show({
                    body: res.data.erro,
                    actions: [Dialog.OKAction()],
                    bsSize: 'sm modal-dialog-centered'
                });
            }
        })
        .catch(error => {
            this.props.setLoading(false);
            return this.dialog.show({
                body:'Ocorreu um erro de conexão com o banco. Por favor, tente novamente em instantes.',
                actions: [Dialog.OKAction()],
                bsSize: 'sm modal-dialog-centered'
            });
        });
    };

    render() {
        const linhasRegras = Object.keys(this.state.regras).map(function (i) {
            return {
                banco: this.state.regras[i].banco ,
                produtoMola: this.state.regras[i].produto_mola ,
                convenio: this.state.regras[i].convenio_nome ,
                produtoBanco: this.state.regras[i].produto_banco ,
                prazo: this.state.regras[i].prazo ,
                valor: formatPercentual(this.state.regras[i].valor) ,
                percentual_adicional: formatPercentual(this.state.regras[i].percentual_adicional)
            };
            }, this);

        const columns = [
            {
                title: 'Banco',
                field: 'banco',
            },
            {
                title: 'Produto Mola',
                field: 'produtoMola',
            },
            {
                title: 'Convênio',
                field: 'convenio',
            },
            {
                title: 'Produto Banco',
                field: 'produtoBanco',
            },
            {
                title: 'Prazo',
                field: 'prazo',
            },
            {
                title: 'Repasse',
                field: 'valor',
            },
            {
                title: 'Percentual adicional de repasse',
                field: 'percentual_adicional',
            },
        ];

        const vigenciasOptions = Object.keys(this.props.repasseTabela.RepassesVigencia).map(function (i) {
            let data_inicial = formatarData2BR(this.props.repasseTabela.RepassesVigencia[i].data_inicial);
            let data_final = formatarData2BR(this.props.repasseTabela.RepassesVigencia[i].data_final);
            return <option key={this.props.repasseTabela.RepassesVigencia[i].id} value={this.props.repasseTabela.RepassesVigencia[i].id}>
                {data_final !== null ? data_inicial + ' - ' + data_final : data_inicial + ' (Vigência atual)'}
            </option>
        }, this);

        return <Modal show={this.props.exibirModal} onHide={() => this.props.updateModalTabelaStatus(false)} dialogClassName="modal-dialog-centered modal-xl" aria-labelledby="modal-tabela-de-repasses">
            <Modal.Header className="bg-pattern-light align-items-center" closeButton>
                <Modal.Title id="modal-tabela-de-repasses">
                    <b>{this.props.repasseTabela.nome + ' '}</b> <small className="badge badge-secondary">{this.props.repasse.agente !== '' ? this.props.repasse.agente : this.props.repasse.unidade}</small>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-pattern-light">
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-5 col-lg-4">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="vigenciaLabel">Vigência</span>
                            </div>
                            <select className="form-control" aria-label="Vigência da tabela de regras de repasse" aria-describedby="vigenciaLabel" id="vigencia_selecionada" onChange={this.changeVigencia} defaultValue={this.props.vigenciaSelecionada}>
                                {vigenciasOptions}
                            </select>
                        </div>
                    </div>
                </div>
                <MaterialTable
                    title={'Regras'}
                    data={linhasRegras}
                    columns={columns}
                    options={{
                        headerStyle: {
                            backgroundColor: '#E9ECEF',
                        },
                        filtering: true,
                        pageSize: 25,
                        pageSizeOptions: [25, 50, 100]
                    }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: 'Não há registros para serem exibidos',
                            filterRow: {
                                filterTooltip: 'Filtrar'
                            }
                        },
                        toolbar: {
                            searchTooltip: 'Buscar',
                            searchPlaceholder: 'Buscar'
                        },
                        pagination: {
                            labelRowsSelect: 'registros',
                            labelDisplayedRows: '{from}-{to} de {count}',
                            firstTooltip: 'Primeira página',
                            previousTooltip: 'Página anterior',
                            nextTooltip: 'Próxima página',
                            lastTooltip: 'Última página'
                        }
                    }}
                />
                <div
                    style={{display: linhasRegras.length > 0 ? 'none' : 'block'}}>
                    <Alert variant="danger" className={'text-center my-5'}>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation-triangle" className="svg-inline--fa fa-exclamation-triangle fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                            <path fill="currentColor" d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"/>
                        </svg>
                        {' '} A vigência selecionada não retornou nenhuma tabela com regras de repasse ativas. Por favor, tente novamente com outra vigência.
                    </Alert>
                </div>
            </Modal.Body>
            <Modal.Footer className="bg-pattern-light">
                <Button variant="secondary" onClick={() => this.props.updateModalTabelaStatus(false)}>
                    Fechar
                </Button>
            </Modal.Footer>
        </Modal>;
    }
}

export default ModalTabelaRepasses;
