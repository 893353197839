import React, {Component} from 'react';
import { formatarData2BR } from '../helpers/Data'
import {formatMoney} from "../helpers/Valor";
import { Alert } from 'react-bootstrap'
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons'

class TabelaConsulta extends Component {

    constructor(props) {
        super(props);
        this.state = {
            repasses: {},
        };
    }

    render() {
        let processados = this.props.repassesProcessados;
        let linhasRepasse = null;
        if (processados) {
            linhasRepasse = Object.keys(processados).map(function (i) {
                let data_vencimento = new Date(processados[i].log.conta_pagar_receber.ContaPagarReceber.data_vencimento + ' 00:00:00');
                return <tr key={processados[i].id}>
                    <td className="align-middle">
                        <span className="badge badge-secondary fs-100">{data_vencimento ? formatarData2BR(data_vencimento) : '-'}</span>
                    </td>
                    <td className="align-middle">
                        <span className="badge badge-info fs-100">{processados[i].log.conta_pagar_receber.ContaPagarReceber.valor_bruto ? formatMoney(processados[i].log.conta_pagar_receber.ContaPagarReceber.valor_bruto) : 'R$ 0,00'}</span>
                    </td>
                    <td className="align-middle">
                        <span className="badge badge-danger fs-100">{processados[i].log.conta_pagar_receber.ContaPagarReceber.descontos ? formatMoney(processados[i].log.conta_pagar_receber.ContaPagarReceber.descontos) : 'R$ 0,00'}</span>
                    </td>
                    <td className="align-middle">
                        <span className="badge badge-danger fs-100">{processados[i].log.conta_pagar_receber.ContaPagarReceber.irrf ? formatMoney(processados[i].log.conta_pagar_receber.ContaPagarReceber.irrf) : 'R$ 0,00'}</span>
                    </td>
                    <td className="align-middle">
                        <span className="badge badge-success fs-100">{processados[i].log.conta_pagar_receber.ContaPagarReceber.valor_liquido ? formatMoney(processados[i].log.conta_pagar_receber.ContaPagarReceber.valor_liquido) : 'R$ 0,00'}</span>
                    </td>
                    <td className="align-middle">
                        <Button variant="secondary" size="sm" title={'Baixar arquivo PDF'} href={this.props.urlRepassesAPI + 'gerar/pdf/' + processados[i].hash_pdf} target={'_blank'}>
                            <FontAwesomeIcon icon={faFilePdf} />
                        </Button>
                        {/*<Button className={'ml-1'} variant="secondary" size="sm" title={'Baixar arquivo Excel'} href={this.props.urlRepassesAPI + 'gerar/xlsx/' + processados[i].hash_pdf} target={'_blank'}>*/}
                        {/*    <FontAwesomeIcon icon={faFileExcel} />*/}
                        {/*</Button>*/}
                    </td>
                </tr>
            }, this);
            if (linhasRepasse !== null) {
                return <div className={'table-responsive'}>
                    <table className="table table-striped table-bordered table-hover bg-white text-center m-0">
                        <thead className="text-white bg-mola">
                        <tr>
                            <th className="align-middle">Vencimento</th>
                            <th className="align-middle">Valor</th>
                            <th className="align-middle">Desconto</th>
                            <th className="align-middle">Retenções</th>
                            <th className="align-middle">Valor líquido</th>
                            <th className="align-middle">Detalhar</th>
                        </tr>
                        </thead>
                        <tbody>
                        {linhasRepasse}
                        </tbody>
                    </table>
                </div>;
            }
        }
        else {
            return <div>
                <Alert variant="danger" className={'text-center my-5'}>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation-triangle" className="svg-inline--fa fa-exclamation-triangle fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                        <path fill="currentColor" d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"/>
                    </svg>
                    {' '}Não foram encontrados repasses no intervalo das datas selecionadas. Por favor, tente novamente com outras datas.
                </Alert>
            </div>;
        }
    }
}

export default TabelaConsulta;
