import React, {Component} from 'react';

class Loading extends Component {

    render() {
        return <div className={"loading d-flex align-items-center justify-content-center " + (this.props.loading ? '' : 'loaded')}>
            <div className="spinner">
                <div className="double-bounce1" />
                <div className="double-bounce2" />
            </div>
        </div>;
    }
}

export default Loading;
