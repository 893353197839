import React, {Component} from 'react';
import Logo from '../img/logo.png';
import ModulosTabs from "./ModulosTabs";
import { Button } from 'react-bootstrap'

class Header extends Component {

    render() {
        return <section className="bg-pattern-light pt-5 pb-5">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-auto">
                        <img src={Logo} alt="Mola Corban" className="img-fluid logo"/>
                    </div>
                    <div className="col-auto">
                        <h1 className="m-0 font-weight-bold">_portal do parceiro</h1>
                        <h6 className="mt-0 mb-3 mb-md-5 font-weight-light">{this.props.origem}</h6>
                        <ModulosTabs/>
                    </div>
                    <div className="col mt-3 mt-sm-0 text-right">
                        <Button variant="secondary" size={'sm'} href={this.props.urlRepassesAPI + 'voltar_mola/' + this.props.hash}>
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="undo" className="svg-inline--fa fa-undo fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path fill="currentColor" d="M212.333 224.333H12c-6.627 0-12-5.373-12-12V12C0 5.373 5.373 0 12 0h48c6.627 0 12 5.373 12 12v78.112C117.773 39.279 184.26 7.47 258.175 8.007c136.906.994 246.448 111.623 246.157 248.532C504.041 393.258 393.12 504 256.333 504c-64.089 0-122.496-24.313-166.51-64.215-5.099-4.622-5.334-12.554-.467-17.42l33.967-33.967c4.474-4.474 11.662-4.717 16.401-.525C170.76 415.336 211.58 432 256.333 432c97.268 0 176-78.716 176-176 0-97.267-78.716-176-176-176-58.496 0-110.28 28.476-142.274 72.333h98.274c6.627 0 12 5.373 12 12v48c0 6.627-5.373 12-12 12z"/>
                            </svg> Voltar para o Mola
                        </Button>
                    </div>
                </div>
            </div>
        </section>;
    }
}

export default Header;
