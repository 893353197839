import React, {Component} from 'react';
import { Button } from 'react-bootstrap';

class ModulosTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: [
                'Repasses'
            ]
        };
    }

    render() {
        const tabs = this.state.tabs.map(function (i) {
            let active = i === 'Repasses';
            return <li key={i} className={ 'nav-item' + (active ? '' : ' ml-2')}>
                <Button variant="light" size={'sm'} className="font-weight-light" active={active}>{i}</Button>
            </li>;
        }, this);
        return <ul className="nav" role="tablist">
            {tabs}
        </ul>;
    }
}

export default ModulosTabs;
