import React, {Component} from 'react';
import Header from "./Header";
import Filtro from "./Filtro";
import Loading from "./Loading";
import TabelaConsulta from "./TabelaConsulta";
import ModalTabelaRepasses from "./ModalTabelaRepasses";
import axios from "axios";
import Footer from "./Footer";
import Dialog from "react-bootstrap-dialog";

class Content extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hash: window.location.pathname.replace('/', ''),
            exibirModalTabela: false,
            repasse: {
                agente: '',
                unidade: ''
            },
            urlRepassesAPI: process.env.REACT_APP_URL_REPASSES_API,
            loading: window.location.pathname.replace('/', '') !== '',
            repassesProcessados: null,
            vigenciaSelecionada: 0,
            repasseTabela: {
                nome: '',
                RepassesVigencia: {}
            }
        };
    }

    componentDidMount() {
        this.carregarDadosRepasse();
    }

    carregarDadosRepasse = () => {
        if(this.state.hash) {
            this.setState({
                loading: true,
            });
            axios.get(this.state.urlRepassesAPI + 'dados_repasse/' + this.state.hash)
            .then(res => {
                if(!res.data.erro) {
                    this.setState({
                        repasse: {
                            agente: res.data[0]['producao_agente'],
                            unidade: res.data[0]['producao_unidade'],
                        },
                        repasseTabela: res && res.data && res.data[0] && res.data[0].RepassesTabela ? res.data[0].RepassesTabela : null,
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                    return this.dialog.show({
                        body: res.data.erro,
                        actions: [Dialog.OKAction()],
                        bsSize: 'sm modal-dialog-centered'
                    });
                }
            })
            .catch(error => {
                this.setState({
                    loading: false
                });
                return this.dialog.show({
                    body:'Ocorreu um erro de conexão com o banco. Por favor, tente novamente em instantes.',
                    actions: [Dialog.OKAction()],
                    bsSize: 'sm modal-dialog-centered'
                });
            });
        } else {
            return this.dialog.show({
                body:'Hash inválido. Por favor, acesse o portal através do link exclusivo gerado para o seu usuário.',
                actions: [Dialog.OKAction()],
                bsSize: 'sm modal-dialog-centered'
            })
        }
    };

    //funcao para exibir ou ocultar o modal de tabela, que sera usado nos componentes do filtro e modal
    updateModalTabelaStatus = (exibir) => {
        this.setState({exibirModalTabela: exibir});
    };

    updateVigenciaSelecionada = (vigencia_id) => {
        this.setState({vigenciaSelecionada: vigencia_id});
    };

    setRepassesProcessados = (processados) => {
        this.setState({repassesProcessados: processados});
    };

    setLoading = (loading) => {
        this.setState({loading: loading});
    };

    render() {
        return <div>
            <Loading loading={this.state.loading}/>
            {this.state.repasseTabela && <ModalTabelaRepasses
                hash={this.state.hash}
                repasse={this.state.repasse}
                repasseTabela={this.state.repasseTabela}
                exibirModal={this.state.exibirModalTabela}
                updateModalTabelaStatus={this.updateModalTabelaStatus}
                setLoading={this.setLoading}
                updateVigenciaSelecionada={this.updateVigenciaSelecionada}
                vigenciaSelecionada={this.state.vigenciaSelecionada}
                urlRepassesAPI={this.state.urlRepassesAPI}
            />}
            <Dialog ref={(component) => {
                this.dialog = component
            }}/>
            <Header
                origem={this.state.repasse.agente ? this.state.repasse.agente : this.state.repasse.unidade}
                hash={this.state.hash}
                urlRepassesAPI={this.state.urlRepassesAPI}
            />
            <section className="bg-white pt-5 pb-5">
                <div className="container-fluid">
                    <Filtro
                        urlRepassesAPI={this.state.urlRepassesAPI}
                        hash={this.state.hash}
                        updateModalTabelaStatus={this.updateModalTabelaStatus}
                        exibirModal={this.state.exibirModalTabela}
                        setRepassesProcessados={this.setRepassesProcessados}
                        setLoading={this.setLoading} repasse={this.state.repasse}
                    />
                    <TabelaConsulta
                        updateVigenciaSelecionada hash={this.state.hash}
                        urlRepassesAPI={this.state.urlRepassesAPI}
                        repassesProcessados={this.state.repassesProcessados}
                    />
                </div>
            </section>
            <Footer />
        </div>;
    }
}

export default Content;
